import './fonts.css';
import './extensions';
import { createTheme } from '@mui/material';
import { common, pink, red } from '@mui/material/colors';
export let theme = createTheme({
    typography: {
        fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
        button: {
            textTransform: 'none',
        },
    },
    shape: {
        borderRadius: 8,
    },
    palette: {
        common: {
            grey: {
                main: '#1E1E1E', // replicant grey
            },
        },
        primary: {
            main: '#1E78E8',
            light: '#4B93EC',
            dark: '#1554A2',
            contrastText: common.white,
        },
        secondary: {
            main: pink['A400'],
        },
        error: {
            main: red[500],
        },
    },
    components: {
        MuiFormControl: {
            defaultProps: {
                fullWidth: true,
                size: 'small',
            },
        },
    },
});
theme = createTheme(theme, {
    palette: {
        white: { main: theme.palette.common.white, contrastText: theme.palette.text.primary },
    },
    components: {
        MuiFormControlLabel: {
            variants: [
                {
                    props: { dense: true, labelPlacement: 'end' },
                    style: { marginRight: theme.spacing(1) },
                },
                {
                    props: { dense: true, labelPlacement: 'top' },
                    style: {
                        marginLeft: theme.spacing(1),
                        marginRight: theme.spacing(1),
                    },
                },
                {
                    props: { dense: true, labelPlacement: 'bottom' },
                    style: {
                        marginLeft: theme.spacing(1),
                        marginRight: theme.spacing(1),
                    },
                },
                {
                    props: { dense: true, labelPlacement: 'start' },
                    style: { marginLeft: theme.spacing(1) },
                },
            ],
        },
        MuiChip: {
            variants: [
                {
                    props: { color: 'white' },
                },
            ],
            styleOverrides: {
                root: {
                    padding: `${theme.spacing(0)} ${theme.spacing(1 / 2)}`,
                },
                iconColorDefault: {
                    color: theme.palette.action.active,
                },
            },
        },
        MuiTextField: {
            defaultProps: theme.components?.MuiFormControl?.defaultProps,
            styleOverrides: {
                root: {
                    '& > .MuiInputBase-root': {
                        backgroundColor: theme.palette.background.default,
                    },
                },
            },
        },
    },
});
